import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import UploadFilesComponent from '@/components/UploadFilesComponent.vue';
import _ from 'lodash';
import { companyConfiguracaoStore, dashboardStore, financeiroParcelaMovimentacaoStore, financeiroParcelaStore, userActionStore } from '@/utils/store-accessor';
import CaixaAbrirComponent from '@/components/CaixaAbrirComponent.vue';
let FinanceiroBaixarTituloComponent = class FinanceiroBaixarTituloComponent extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: '', value: 'idx', width: '10px', align: 'center' },
            { text: 'Tipo', value: 'financeiro_categoria_nome' },
            { text: 'Vencimento', value: 'vencimento' },
            { text: 'Valor integral', value: 'valor_integral' },
            { text: 'Valor original', value: 'valor_original' },
            { text: 'Valor atualizado', value: 'valor_atualizado' },
            { text: 'Valor pago', value: 'valor_pago' }
        ];
        this.loading = false;
        this.codigoJaUtilizado = false;
        this.codigoJaUtilizadoMensagem = '';
        this.comprovantesUploadFiles = [];
        this.abaterDiferenca = false;
        this.selectedAreaParaAbater = null;
        this.dialogAbrirCaixa = false;
        this.menu = false;
        this.marcarComoQuitado = false;
        this.financeiroParcelasMovimentacoes = [];
        this.baixaFinalizada = false;
        this.itemsFormasDeRecebimento = [
            {
                valor: 0,
                isCartao: false,
                formaDeRecebimento: 'Dinheiro',
                codigo: '',
                terminal: ''
            }
        ];
        this.optionsFormasDeRecebimento = [
            { forma: 'Dinheiro', isCartao: false },
            { forma: 'Cartão de Crédito', isCartao: true },
            { forma: 'Cartão de Débito', isCartao: true },
            { forma: 'Boleto', isCartao: false },
            { forma: 'Transferência Eletrônica', isCartao: false },
            { forma: 'Cheque', isCartao: false },
            { forma: 'PIX', isCartao: false }
        ];
        this.financeiroParcelaMovimentacaoIds = [];
    }
    // public optionsBandeirasCartao = [
    //   'VISA',
    //   'Mastercard'
    // ]
    get getValorTotal() {
        let valorTotal = 0;
        this.propTitulosItems.forEach((item) => {
            valorTotal += item.valor_atualizado;
        });
        return +valorTotal.toFixed(2);
    }
    get disableButton() {
        if (this.itemsFormasDeRecebimento.length <= 0) {
            return true;
        }
        // Só pode baixar com zero se marcou que vai quitar
        if (this.getValorTotalFormasDeRecebimento == 0 && !this.marcarComoQuitado) {
            return true;
        }
    }
    get disableMarcarComoQuitado() {
        return (this.getDiferencaValorTotal > this.getValorTotal ||
            (this.getValorTotalFormasDeRecebimento / this.getValorTotal) * 100 <
                // @ts-ignore
                100 - this.companyConfiguracoes.baixa_menor_percentual_maximo_desconto ||
            this.baixaFinalizada);
    }
    get dashboardCaixa() {
        return dashboardStore.dashboardCaixa;
    }
    get getValorTotalFormasDeRecebimento() {
        let valorTotal = 0;
        this.itemsFormasDeRecebimento.forEach((item) => {
            valorTotal += item.valor;
        });
        return +valorTotal.toFixed(2);
    }
    get getValorTotalJaPago() {
        let valorPago = 0;
        this.propTitulosItems.forEach((item) => {
            valorPago += item.valor_pago;
        });
        return +valorPago.toFixed(2);
    }
    get companyConfiguracoes() {
        return companyConfiguracaoStore.companyConfiguracoes;
    }
    get getDiferencaValorTotal() {
        return this.getValorTotal - this.getValorTotalFormasDeRecebimento;
    }
    get userActionsQuitadoAMenorAllow() {
        return userActionStore.quitadoAMenorAllow;
    }
    async onChangeGetDiferencaValorTotal() {
        if (this.getDiferencaValorTotal >= this.getValorTotal ||
            (this.getValorTotalFormasDeRecebimento / this.getValorTotal) * 100 <
                // @ts-ignore
                100 - this.companyConfiguracoes.baixa_menor_percentual_maximo_desconto) {
            this.marcarComoQuitado = false;
        }
    }
    async onchangeAbrirCaixa(val, oldVal) {
        if (!val) {
            await dashboardStore.getDashboardCaixa();
            if (oldVal) {
                this.loading = false;
            }
        }
    }
    async abrirCaixa() {
        this.dialogAbrirCaixa = !this.dialogAbrirCaixa;
        await dashboardStore.getDashboardCaixa();
    }
    async onChangeCodigo(item) {
        if (item.formaDeRecebimento === 'PIX' && item.codigo) {
            item.codigo = item.codigo.replace(/\s/g, '');
        }
        this.codigoJaUtilizado = false;
        this.codigoJaUtilizadoMensagem = '';
        if (item.codigo || item.terminal) {
            const res = await financeiroParcelaMovimentacaoStore.validateCodigoTerminal({
                codigo: item.codigo,
                terminal: item.terminal,
                forma_de_pagamento: item.formaDeRecebimento
            });
            if (res) {
                if (item.formaDeRecebimento === 'PIX' ||
                    item.formaDeRecebimento === 'Transferência Eletrônica') {
                    this.codigoJaUtilizado = true;
                    this.codigoJaUtilizadoMensagem =
                        'Esse Código de Comprovante já foi utilizado!';
                }
                else {
                    this.codigoJaUtilizado = true;
                    this.codigoJaUtilizadoMensagem =
                        'Essa combinação de NSU e Terminal já foi utilizada!';
                }
            }
        }
    }
    async adicionarFormaDeRecebimento(formaDeRecebimento) {
        this.itemsFormasDeRecebimento.push({
            valor: 0,
            isCartao: formaDeRecebimento.isCartao,
            formaDeRecebimento: formaDeRecebimento.forma,
            codigo: '',
            terminal: ''
        });
    }
    async onChangeAbaterDiferenca() {
        if (!this.abaterDiferenca) {
            this.selectedAreaParaAbater = null;
        }
    }
    async removeFormaDeRecebimento(item) {
        const index = _.findIndex(this.itemsFormasDeRecebimento, (forma) => forma === item);
        this.itemsFormasDeRecebimento.splice(index, 1);
    }
    async onChangeFormaDeRecebimento(item) {
        this.codigoJaUtilizado = false;
        this.codigoJaUtilizadoMensagem = '';
        item.codigo = '';
        item.terminal = '';
        if (item.formaDeRecebimento.includes('Cartão')) {
            item.isCartao = true;
            return;
        }
        item.isCartao = false;
    }
    onCancel() {
        this.$emit('on-cancel');
    }
    async baixarTitulos() {
        this.loading = true;
        // @ts-ignore
        if (!(this.dashboardCaixa.caixa_id && this.dashboardCaixa.status === 1)) {
            this.dialogAbrirCaixa = true;
            return;
        }
        this.propTitulosItems.forEach((item) => {
            if (!item.valor_atualizado) {
                item.valor_atualizado = item.valor_integral;
            }
        });
        this.financeiroParcelaMovimentacaoIds = await financeiroParcelaMovimentacaoStore.createFinanceiroParcelaMovimentacaoTitulos({
            files: this.comprovantesUploadFiles,
            titulos: _.map(this.propTitulosItems, (item) => {
                return {
                    id: item.id,
                    vencimento: item.vencimento,
                    valor_atualizado: item.valor_atualizado,
                    valor_pago: item.valor_pago,
                    aluno_id: item.aluno_id
                };
            }),
            titulosMovimentacoes: this.itemsFormasDeRecebimento,
            abaterDiferenca: this.abaterDiferenca,
            areaDeAbatimento: this.selectedAreaParaAbater,
            marcarComoQuitado: this.marcarComoQuitado
        });
        this.loading = false;
        if (this.financeiroParcelaMovimentacaoIds) {
            await dashboardStore.getDashboardCaixa();
            this.baixaFinalizada = true;
        }
    }
    async imprimirRecibo() {
        let reciboHTML = null;
        reciboHTML = await financeiroParcelaStore.imprimirRecibo({
            financeiro_parcela_movimentacao_ids: this.financeiroParcelaMovimentacaoIds
        });
        if (reciboHTML) {
            const w = window.open();
            w.document.write(reciboHTML);
        }
    }
    async mounted() {
        await companyConfiguracaoStore.getCompanyConfiguracoes();
    }
};
__decorate([
    Prop({ type: Array, default: () => [] })
], FinanceiroBaixarTituloComponent.prototype, "propTitulosItems", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], FinanceiroBaixarTituloComponent.prototype, "propDisableImprimir", void 0);
__decorate([
    Watch('getDiferencaValorTotal')
], FinanceiroBaixarTituloComponent.prototype, "onChangeGetDiferencaValorTotal", null);
__decorate([
    Watch('dialogAbrirCaixa')
], FinanceiroBaixarTituloComponent.prototype, "onchangeAbrirCaixa", null);
FinanceiroBaixarTituloComponent = __decorate([
    Component({
        components: {
            CaixaAbrirComponent,
            UploadFilesComponent
        }
    })
], FinanceiroBaixarTituloComponent);
export default FinanceiroBaixarTituloComponent;
