export function newFinanceiroContaPagar() {
    return {
        tipo: 1,
        numero_documento: null,
        numero_parcelas: 1,
        data_vencimento: null,
        data_pagamento: null,
        data_emissao: null,
        financeiro_categoria_id: null,
        conta_bancaria_id: null,
        observacao: '',
        valor_integral: null,
        total_pago: null,
        documento_fiscal_id: null,
        valor_pago: null,
        aluno_id: null,
        fornecedor_id: null,
        company_id: null,
        unidade_id: null,
    };
}
export function newFinanceiroContaReceber() {
    return {
        tipo: 0,
        numero_documento: null,
        numero_parcelas: 1,
        data_vencimento: null,
        data_pagamento: null,
        financeiro_categoria_id: null,
        conta_bancaria_id: null,
        data_emissao: null,
        observacao: '',
        valor_integral: null,
        total_pago: null,
        documento_fiscal_id: null,
        valor_pago: null,
        aluno_id: null,
        fornecedor_id: null,
        company_id: null,
        unidade_id: null,
    };
}
export function newFinanceiroContaReceberComplete() {
    return {
        tipo: 0,
        numero_documento: null,
        numero_parcelas: 1,
        data_vencimento: null,
        data_pagamento: null,
        financeiro_categoria_id: null,
        conta_bancaria_id: null,
        data_emissao: null,
        observacao: '',
        valor_integral: null,
        total_pago: null,
        documento_fiscal_id: null,
        valor_pago: null,
        aluno_id: null,
        fornecedor_id: null,
        company_id: null,
        unidade_id: null,
        numero_vezes: null,
        periodicidade_id: null,
        status_id: 1,
        vencimento_inicial: null,
        colaborador_id: null,
    };
}
export function newFinanceiroContaPagarComplete() {
    return {
        tipo: 1,
        numero_documento: null,
        numero_parcelas: 1,
        data_vencimento: null,
        data_pagamento: null,
        financeiro_categoria_id: null,
        conta_bancaria_id: null,
        data_emissao: null,
        observacao: '',
        valor_integral: null,
        total_pago: null,
        documento_fiscal_id: null,
        valor_pago: null,
        aluno_id: null,
        fornecedor_id: null,
        company_id: null,
        unidade_id: null,
        numero_vezes: null,
        periodicidade_id: null,
        status_id: 1,
        vencimento_inicial: null,
        colaborador_id: null,
    };
}
