import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
let DateFieldComponent = class DateFieldComponent extends Vue {
    constructor() {
        super(...arguments);
        // @Prop() public value;
        this.valorDesconto = 0;
        this.valorAcrescimo = 0;
        this.percentualDesconto = 0;
        this.valorMaximoDesconto = 0;
        this.naoRodarWatchValor = false;
        this.naoRodarWatchPercentual = false;
        this.percentualMaximoDesconto = null;
    }
    atualizaValor() {
        if (this.percentualDesconto > this.percentualMaximoDesconto) {
            this.percentualDesconto = this.percentualMaximoDesconto;
            this.valorDesconto = this.valorMaximoDesconto;
        }
        this.valorDesconto = (this.percentualDesconto / 100) * this.propValor;
        this.$emit('input', {
            valorDesconto: this.valorDesconto,
            percentualDesconto: this.percentualDesconto,
            valorAcrescimo: this.valorAcrescimo
        });
    }
    atualizaPercentual() {
        const perc = (this.valorDesconto / this.propValor) * 100;
        if (perc > this.percentualMaximoDesconto) {
            this.percentualDesconto = this.percentualMaximoDesconto;
            this.atualizaValor();
        }
        else {
            this.percentualDesconto = perc;
        }
        this.$emit('input', {
            valorDesconto: this.valorDesconto,
            percentualDesconto: this.percentualDesconto,
            valorAcrescimo: this.valorAcrescimo
        });
    }
    async mounted() {
        this.valorMaximoDesconto = this.propDescontoValorMaximo;
        if (this.valorMaximoDesconto === 0 || !this.valorMaximoDesconto) {
            this.valorMaximoDesconto =
                (this.propDescontoPercentualMaximo / 100) * this.propValor;
        }
        this.percentualMaximoDesconto = this.propDescontoPercentualMaximo;
        if (this.propDescontoValorMaximo > 0) {
            const perc = (this.propDescontoValorMaximo / this.propValor) * 100;
            if (perc < this.percentualMaximoDesconto) {
                this.percentualMaximoDesconto = perc;
            }
        }
    }
};
__decorate([
    Prop()
], DateFieldComponent.prototype, "propDescontoPercentualMaximo", void 0);
__decorate([
    Prop()
], DateFieldComponent.prototype, "propDescontoValorMaximo", void 0);
__decorate([
    Prop()
], DateFieldComponent.prototype, "propValor", void 0);
__decorate([
    Prop({ type: Array, default: () => Object })
], DateFieldComponent.prototype, "value", void 0);
DateFieldComponent = __decorate([
    Component
], DateFieldComponent);
export default DateFieldComponent;
