import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import _ from 'lodash';
import { financeiroParcelaStore } from '@/utils/store-accessor';
let FinanceiroCancelarTituloComponent = class FinanceiroCancelarTituloComponent extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Tipo', value: 'financeiro_categoria_nome' },
            { text: 'Vencimento', value: 'vencimento' },
            { text: 'Valor integral', value: 'valor_integral' },
            { text: 'Valor original', value: 'valor_original' },
            { text: 'Valor atualizado', value: 'valor_atualizado' },
            { text: 'Valor pago', value: 'valor_pago' },
        ];
        this.motivoCancelamento = '';
    }
    onCancel() {
        this.$emit('on-cancel');
    }
    async cancelarTitulos() {
        const ids = _.map(this.propTitulosItems, 'id');
        await financeiroParcelaStore.cancelarFinanceiroParcelas({
            ids,
            observacao: this.motivoCancelamento,
        });
        this.onCancel();
    }
};
__decorate([
    Prop({ type: Array, default: () => [] })
], FinanceiroCancelarTituloComponent.prototype, "propTitulosItems", void 0);
FinanceiroCancelarTituloComponent = __decorate([
    Component
], FinanceiroCancelarTituloComponent);
export default FinanceiroCancelarTituloComponent;
