import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { caixaStore, contaBancariaStore, dashboardStore, } from '@/utils/store-accessor';
let CaixaAbrirComponent = class CaixaAbrirComponent extends Vue {
    constructor() {
        super(...arguments);
        this.saldoInicial = 0;
        this.loading = false;
        this.selectedContaBancaria = null;
    }
    onCancel() {
        this.loading = false;
        this.$emit('on-cancel');
    }
    get disableButton() {
        if (this.saldoInicial < 0 || this.loading) {
            return true;
        }
    }
    get contasBancarias() {
        return contaBancariaStore.contasBancarias;
    }
    async abrirCaixa() {
        this.loading = true;
        const caixa = await caixaStore.abrirCaixa({
            saldo_inicial: this.saldoInicial,
            status: 1,
            observacao: 'Abertura do caixa',
        });
        if (!caixa) {
            this.onCancel();
            return;
        }
        await contaBancariaStore.createContaBancariaMovimentacoes([
            {
                data_hora: new Date().toISOString(),
                valor: -this.saldoInicial,
                descricao: 'Retirada para abertura de caixa',
                conta_id: this.selectedContaBancaria?.id,
                tipo: 2,
                conta_enviou_id: null,
                conta_recebeu_id: null,
            },
        ]);
        await dashboardStore.getDashboardCaixa();
        this.onCancel();
    }
    async mounted() {
        await contaBancariaStore.getContasBancarias(true);
    }
};
__decorate([
    Prop({ type: String, default: '' })
], CaixaAbrirComponent.prototype, "subtitle", void 0);
CaixaAbrirComponent = __decorate([
    Component
], CaixaAbrirComponent);
export default CaixaAbrirComponent;
