var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Autenticar operação")])]),_c('v-card-subtitle',[_vm._v(" Para prosseguir você precisa informar um código de autenticação ")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"debounce":100,"name":"Código","vid":"codigo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],staticStyle:{"font-size":"18pt"},attrs:{"label":"Código","error-messages":errors.concat( _vm.codeError)},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('div',{staticStyle:{"cursor":"pointer"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.statusValidacao.authorized)?_c('v-btn',{attrs:{"small":""},on:{"click":_vm.validateCode}},[_vm._v(" Validar ")]):_c('v-btn',{staticClass:"success",attrs:{"small":""}},[_vm._v(" Código valido! ")])]}}],null,true)})],1)]},proxy:true}],null,true),model:{value:(_vm.codigo),callback:function ($$v) {_vm.codigo=$$v},expression:"codigo"}})]}}],null,true)})],1)],1),(_vm.statusValidacao.authorized)?_c('v-row',[_c('v-col',[_vm._v(" Usuário reponsável pelo liberação: "),_c('b',[_vm._v(_vm._s(_vm.statusValidacao.allowed_by_name))])])],1):_vm._e()],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.$emit('on-cancel');}}},[_vm._v("Voltar")]),_c('v-btn',{attrs:{"text":"","color":"success","disabled":!_vm.statusValidacao.authorized},on:{"click":_vm.onSubmit}},[_vm._v("Sim, eu confirmo a operação")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }