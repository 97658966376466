var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v(" "+_vm._s(_vm.propRemoveMode ? 'Retirar protesto de títulos' : 'Protestar títulos')+" ")])]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.propTitulosItems,"footer-props":{
            itemsPerPageOptions:[-1]
          },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.vencimento",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.vencimento))+" ")]}},{key:"item.valor_integral",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(item.valor_integral))+" ")]}},{key:"item.valor_original",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(item.valor_original))+" ")]}},{key:"item.valor_atualizado",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(item.valor_atualizado))+" ")]}},{key:"item.valor_pago",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(item.valor_pago))+" ")]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"label":"Observações","rows":"3"},model:{value:(_vm.observacao),callback:function ($$v) {_vm.observacao=$$v},expression:"observacao"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.onCancel}},[_vm._v("voltar")]),_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":_vm.protestar}},[_vm._v("Sim, eu confirmo o protesto")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }