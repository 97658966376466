import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ocorrenciaStore } from '@/utils/store-accessor';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
let LogShowComponent = class LogShowComponent extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.dialog = false;
        this.items = [];
        this.headers = [
            {
                text: 'Criado em',
                value: 'created_at',
            },
            {
                text: 'Criado por',
                value: 'full_name',
            },
            {
                text: 'Entidade',
                value: 'entidade_id',
            },
            {
                text: 'Observação',
                value: 'observacao',
            },
            {
                text: 'Mensagem',
                value: 'syngoo_mensagem_name',
            },
        ];
    }
    async show(id) {
        this.loading = true;
        const queryIn = {
            fields: ['ocorrencia.created_at'],
            params: {
                [`ocorrencia_relacionamento_${this.propEntidadeNome}.${this.propEntidadeNome}_id`]: {
                    type: 'int',
                    value: [this.propEntidadeId],
                },
            },
            skip: 0,
            limit: 500,
        };
        this.items = await ocorrenciaStore.getOcorrenciasQuery(queryIn);
        this.dialog = true;
        this.loading = false;
    }
};
__decorate([
    Prop(Number)
], LogShowComponent.prototype, "propEntidadeId", void 0);
__decorate([
    Prop(String)
], LogShowComponent.prototype, "propEntidadeNome", void 0);
LogShowComponent = __decorate([
    Component({
        components: {
            OpenNewTabComponent,
            TextExpandableComponent,
        },
    })
], LogShowComponent);
export default LogShowComponent;
