import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import ConfirmarEntregarLivroComponent from '@/components/ConfirmarEntregarLivroComponent.vue';
import { syngooMensagemConfiguracaoStore } from '@/store';
let EnviarWhatsButton = class EnviarWhatsButton extends Vue {
    constructor() {
        super(...arguments);
        this.ocorrenciaId = null;
    }
    getSemCelular() {
        if (this.propUnidadeId) {
            // drive
            if (this.propUnidadeResponsavelFinanceiroCelular) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            if (this.propAlunoResponsavelFinanceiro) {
                // escola e aluno responsavel financeiro
                if (this.propAlunoCelular) {
                    return false;
                }
                else {
                    return true;
                }
            }
            else {
                // escola e aluno nao responsavel financeiro
                if (this.propResponsavelCelular) {
                    return false;
                }
                else {
                    return true;
                }
            }
        }
    }
    async enviarNoWhatsAbrindoAba() {
        // @ts-ignore
        const envio = await syngooMensagemConfiguracaoStore.SyngooEnviaWhats({
            financeiro_parcela_id: this.propFinanceiroParcelaId,
            syngoo_mensagem_id: this.propSyngooMensagemId,
            ja_possui_ocorrencia: !!this.ocorrenciaId,
        });
        window.open(`https://wa.me/+55${envio.phone}?text=${envio.message}`, '_blank');
        this.ocorrenciaId = -1;
    }
    async enviarNaApiOficial() {
        // @ts-ignore
        await syngooMensagemConfiguracaoStore.SyngooEnviaWhats({
            financeiro_parcela_id: this.propFinanceiroParcelaId,
            syngoo_mensagem_id: this.propSyngooMensagemId,
            ja_possui_ocorrencia: !!this.ocorrenciaId,
        });
        this.ocorrenciaId = -1;
    }
    async mounted() {
        this.ocorrenciaId = this.propOcorrenciaId;
    }
};
__decorate([
    Prop({ type: Number })
], EnviarWhatsButton.prototype, "propSyngooMensagemId", void 0);
__decorate([
    Prop({ type: Number })
], EnviarWhatsButton.prototype, "propFinanceiroParcelaId", void 0);
__decorate([
    Prop({ type: Number })
], EnviarWhatsButton.prototype, "propUnidadeId", void 0);
__decorate([
    Prop({ type: Number })
], EnviarWhatsButton.prototype, "propUnidadeResponsavelFinanceiroCelular", void 0);
__decorate([
    Prop({ type: Number })
], EnviarWhatsButton.prototype, "propAlunoResponsavelFinanceiro", void 0);
__decorate([
    Prop({ type: Number })
], EnviarWhatsButton.prototype, "propAlunoCelular", void 0);
__decorate([
    Prop({ type: Number })
], EnviarWhatsButton.prototype, "propResponsavelCelular", void 0);
__decorate([
    Prop({ type: Number })
], EnviarWhatsButton.prototype, "propOcorrenciaId", void 0);
__decorate([
    Prop({ type: Boolean })
], EnviarWhatsButton.prototype, "propEnvioAutomatico", void 0);
__decorate([
    Prop({ type: Boolean })
], EnviarWhatsButton.prototype, "propEnvioManual", void 0);
__decorate([
    Prop({ type: Boolean })
], EnviarWhatsButton.prototype, "propSyngooConfiguracaoStatus", void 0);
__decorate([
    Prop({ type: String, default: 'x-small' })
], EnviarWhatsButton.prototype, "propSizeButton", void 0);
EnviarWhatsButton = __decorate([
    Component({
        components: {
            ConfirmarEntregarLivroComponent,
        },
    })
], EnviarWhatsButton);
export default EnviarWhatsButton;
