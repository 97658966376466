import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { userActionStore, financeiroParcelaMovimentacaoStore, } from '@/utils/store-accessor';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import RequestPermissionComponent from '@/components/RequestPermissionComponent.vue';
let FinanceiroEstornoTituloComponent = class FinanceiroEstornoTituloComponent extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Tipo', value: 'financeiro_categoria_nome' },
            { text: 'Vencimento', value: 'vencimento' },
            { text: 'Valor integral', value: 'valor_integral' },
            { text: 'Valor original', value: 'valor_original' },
            { text: 'Valor atualizado', value: 'valor_atualizado' },
            { text: 'Valor pago', value: 'valor_pago' },
        ];
        this.requestPermissionDialog = false;
        this.dataPagamento = null;
        this.valor = null;
        this.observacao = '';
        this.actionId = null;
        this.loading = false;
    }
    onCancel() {
        this.$emit('on-cancel');
    }
    onConfirm() {
        this.$emit('on-confirm');
    }
    get showMessage() {
        return (this.propFinanceiro.caixa?.status == 2 &&
            !(this.estornarBaixaTituloCaixaConferidoObject.allow ||
                this.estornarBaixaTituloCaixaConferidoObject.request_permission));
    }
    get estornarBaixaObject() {
        return userActionStore.estornarBaixaObject;
    }
    get estornarBaixaTituloCaixaConferidoObject() {
        return userActionStore.estornarBaixaTituloCaixaConferidoObject;
    }
    get disabledSubmit() {
        if (this.loading) {
            return true;
        }
        let retorno = true;
        // com caixa conferido?
        if (this.propFinanceiro.caixa?.status == 2) {
            if (this.estornarBaixaTituloCaixaConferidoObject?.allow ||
                this.estornarBaixaTituloCaixaConferidoObject?.request_permission) {
                retorno = false;
            }
        }
        else {
            if (this.estornarBaixaTituloCaixaConferidoObject?.allow ||
                this.estornarBaixaTituloCaixaConferidoObject?.request_permission ||
                this.estornarBaixaObject?.allow ||
                this.estornarBaixaObject?.request_permission) {
                retorno = false;
            }
        }
        return retorno;
    }
    async submit(allowed = false) {
        this.loading = true;
        if (this.propFinanceiro.caixa?.status === 2 && // 2 - Caixa Conferido
            this.estornarBaixaTituloCaixaConferidoObject?.request_permission &&
            !this.requestPermissionDialog) {
            this.actionId = this.estornarBaixaTituloCaixaConferidoObject.action_id;
            this.requestPermissionDialog = true;
            return;
        }
        if (this.propFinanceiro.caixa?.status === 1 && // 1 - Caixa Aberto
            this.estornarBaixaObject?.request_permission &&
            !this.requestPermissionDialog) {
            this.actionId = this.estornarBaixaObject.action_id;
            this.requestPermissionDialog = true;
            return;
        }
        if (allowed) {
            this.requestPermissionDialog = false;
            const response = await financeiroParcelaMovimentacaoStore.estornar(this.propFinanceiro.id);
            if (!response) {
                this.$swal({
                    title: 'Erro no cancelamento da nota',
                    text: 'O cancelamento da nota não foi realizado. Entre no portal da prefeitura do seu município e realize o cancelamento.',
                    icon: 'error',
                    confirmButtonText: 'Entendi',
                });
                return;
            }
            this.onConfirm();
        }
        this.loading = false;
    }
};
__decorate([
    Prop({ type: Object })
], FinanceiroEstornoTituloComponent.prototype, "propFinanceiro", void 0);
FinanceiroEstornoTituloComponent = __decorate([
    Component({
        components: {
            DateFieldComponent,
            OpenNewTabComponent,
            RequestPermissionComponent,
        },
    })
], FinanceiroEstornoTituloComponent);
export default FinanceiroEstornoTituloComponent;
