import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import { contratoStore } from '@/utils/store-accessor';
let ContratoChipComponent = class ContratoChipComponent extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.contratoDados = null;
        this.timer = 0;
    }
    async mostrarDetalhe(contrato) {
        if (this.contratoDados && this.contratoDados.id == contrato.id) {
            return;
        }
        this.loading = true;
        this.contratoDados = null;
        this.contratoDados = await contratoStore.getContrato(contrato.id);
        this.loading = false;
    }
};
__decorate([
    Prop({ type: Array })
], ContratoChipComponent.prototype, "propContratoItem", void 0);
ContratoChipComponent = __decorate([
    Component({
        components: {
            OpenNewTabComponent,
        },
    })
], ContratoChipComponent);
export default ContratoChipComponent;
