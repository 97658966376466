import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { userActionStore, userStore } from '@/utils/store-accessor';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
let RequestPermissionComponent = class RequestPermissionComponent extends Vue {
    constructor() {
        super(...arguments);
        this.codigo = '';
        this.statusValidacao = { authorized: null, token: null };
        this.jaFoiValidado = false;
    }
    get userActions() {
        return userActionStore.userActions;
    }
    get supervisores() {
        return userStore.users.filter((usr) => {
            return usr.cargo_id === +this.propCargoParaValidar;
        });
    }
    get codeError() {
        if (this.statusValidacao.authorized === null) {
            return [];
        }
        return !this.statusValidacao.authorized ? ['Código inválido'] : [];
    }
    async validateCode() {
        this.statusValidacao = {
            authorized: false,
            token: null,
        };
        const validFields = await this.$refs.observer.validate();
        if (!validFields) {
            return;
        }
        this.jaFoiValidado = true;
        this.statusValidacao = await userStore.verifyTOTPCode({
            code: this.codigo,
            actionId: this.propActionId,
        });
    }
    async onSubmit() {
        const validFields = await this.$refs.observer.validate();
        if (!validFields) {
            return;
        }
        this.$emit('on-validate', this.statusValidacao.token);
        this.codigo = '';
        this.statusValidacao = { authorized: false, token: null };
        this.jaFoiValidado = false;
    }
    async mounted() {
        await userStore.getUsers(true);
    }
};
__decorate([
    Prop()
], RequestPermissionComponent.prototype, "propCargoParaValidar", void 0);
__decorate([
    Prop()
], RequestPermissionComponent.prototype, "propActionId", void 0);
RequestPermissionComponent = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
        },
    })
], RequestPermissionComponent);
export default RequestPermissionComponent;
