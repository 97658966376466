import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { financeiroParcelaRenegociacaoStore } from '@/utils/store-accessor';
import ClickableCard from '@/components/ClickableCard.vue';
import RequestPermissionComponent from '@/components/RequestPermissionComponent.vue';
let FinanceiroParcelaRenegociacaoComponent = class FinanceiroParcelaRenegociacaoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.infoRenegociacaoItem = {};
        this.loading = true;
    }
    onCancel() {
        this.$emit('on-cancel');
    }
    async mounted() {
        this.loading = true;
        this.infoRenegociacaoItem =
            await financeiroParcelaRenegociacaoStore.getFinanceiroParcelaRengociacao(this.propFinanceiroParcelaId);
        this.loading = false;
    }
};
__decorate([
    Prop({ type: Number })
], FinanceiroParcelaRenegociacaoComponent.prototype, "propFinanceiroParcelaId", void 0);
FinanceiroParcelaRenegociacaoComponent = __decorate([
    Component({
        components: {
            ClickableCard,
            RequestPermissionComponent,
        },
    })
], FinanceiroParcelaRenegociacaoComponent);
export default FinanceiroParcelaRenegociacaoComponent;
