import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { dashboardStore, alunoStore } from '@/store';
import ShowPopup from '@/components/ShowPopup.vue';
import _ from 'lodash';
import ClickableCard from '@/components/ClickableCard.vue';
import FinanceiroAlunoComponent from '@/components/FinanceiroAlunoComponent.vue';
import OcorrenciaComponent from '@/components/OcorrenciaComponent.vue';
import EntidadeLinkComponent from '@/components/EntidadeLinkComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import ContratoChipComponent from '@/components/ContratoChipComponent.vue';
let Cobranca = class Cobranca extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.search = '';
        this.objecaoSelected = null;
        this.tab = 'tab_financeiro';
        this.contatoHeaders = [
            { text: 'Origem', value: 'origem_id' },
            { text: 'Etapa da cobrança', value: 'tipo_contato' },
            { text: 'Motivo do não pagamento/Observações', value: 'observacao' },
            { text: 'Gerou tarefa?', value: '' },
            { text: 'Gerou reuniåo?', value: '' },
            { text: 'Criado por', value: 'created_by' },
            { text: 'Criado em', value: 'created_at' },
        ];
        this.cobrancaHeaders = [
            { text: 'Aluno', value: 'aluno_nome' },
            { text: 'Status', value: 'contratos' },
            { text: 'Última aula presente', value: 'ultima_atividade_presente' },
            { text: 'Último recebimento', value: 'ultimo_recebimento' },
            { text: 'Dias devendo', value: 'dias_atraso' },
            {
                text: 'Quantidade de parcelas vencidas',
                value: 'quantidade_parcelas_atraso',
            },
            {
                text: 'Valor em atraso - Curso',
                value: 'mensalidade_atraso_valor',
            },
            {
                text: 'Valor em atraso - Material',
                value: 'material_didatico_atraso_valor',
            },
            {
                text: 'Valor em atraso - Renegociações',
                value: 'renegociacao_atraso_valor',
            },
            { text: 'Valor em atraso - Outros', value: 'outros_atraso_valor' },
            { text: 'Contatos', value: 'ultimo_contato' },
            { text: '', value: 'actions' },
        ];
        this.aluno = null;
        this.diaSemanaQuantidadeAlunos = {
            'Domingo': 0,
            'Segunda-feira': 0,
            'Terça-feira': 0,
            'Quarta-feira': 0,
            'Quinta-feira': 0,
            'Sexta-feira': 0,
            'Sábado': 0,
            'Não disponível': 0,
        };
        this.propertyToShowDetails = [
            'Alunos que possuem até 2 parcelas vencidas',
            'Alunos que possuem entre 3 e 4 parcelas vencidas',
            'Alunos que possuem acima de 4 parcelas vencidas',
            'Vigente',
            'Trancado',
            'Evadido',
            'Desistente',
            'Sem contrato',
            'Matriculado',
            'Pendente rematrícula',
            'Rematriculado',
            'Não aceitou rematricular',
            'Idioma finalizado',
            'Domingo',
            'Segunda-feira',
            'Terça-feira',
            'Quarta-feira',
            'Quinta-feira',
            'Sexta-feira',
            'Sábado',
            'Não disponível',
        ];
        this.propertyToShowContratoStatus = [];
        this.loadingItems = false;
        this.dataTableItems = [];
        this.selectedAluno = null;
        this.ocorrenciaOrigemSelected = null;
        this.panelOpen = false;
    }
    get dashboardCobranca() {
        return dashboardStore.dashboardCobranca;
    }
    get alunos_ate_2_vencidas() {
        return _.filter(
        // @ts-ignore
        this.dashboardCobranca, 
        // @ts-ignore
        (item) => item.quantidade_parcelas_atraso <= 2).length;
    }
    get alunos_entre_3_e_4_vencidas() {
        return _.filter(
        // @ts-ignore
        this.dashboardCobranca, (item) => 
        // @ts-ignore
        item.quantidade_parcelas_atraso >= 3 &&
            // @ts-ignore
            item.quantidade_parcelas_atraso <= 4).length;
    }
    get contrato_status() {
        const items = [];
        _.forEach(this.dashboardCobranca, (item) => {
            // @ts-ignore
            if (!item.contratos) {
                items.push({ contrato_status: null });
            }
            else {
                // @ts-ignore
                _.forEach(item.contratos, (el) => {
                    // @ts-ignore
                    items.push({ contrato_status: el.status_id });
                });
            }
        });
        // @ts-ignore
        return _.countBy(items, 'contrato_status');
    }
    get alunos_acima_4_vencidas() {
        return _.filter(
        // @ts-ignore
        this.dashboardCobranca, 
        // @ts-ignore
        (item) => item.quantidade_parcelas_atraso > 4).length;
    }
    get dashboardCobrancaItemsFiltered() {
        const ate2 = this.propertyToShowDetails.includes('Alunos que possuem até 2 parcelas vencidas');
        const entre3e4 = this.propertyToShowDetails.includes('Alunos que possuem entre 3 e 4 parcelas vencidas');
        const mais4 = this.propertyToShowDetails.includes('Alunos que possuem acima de 4 parcelas vencidas');
        const filtroStatus = [];
        if (this.propertyToShowDetails.includes('Vigente')) {
            filtroStatus.push(1);
        }
        if (this.propertyToShowDetails.includes('Trancado')) {
            filtroStatus.push(3);
        }
        if (this.propertyToShowDetails.includes('Evadido')) {
            filtroStatus.push(5);
        }
        if (this.propertyToShowDetails.includes('Desistente')) {
            filtroStatus.push(6);
        }
        if (this.propertyToShowDetails.includes('Sem contrato')) {
            filtroStatus.push(null);
        }
        if (this.propertyToShowDetails.includes('Removido')) {
            filtroStatus.push(9);
        }
        if (this.propertyToShowDetails.includes('Matriculado')) {
            filtroStatus.push(10);
        }
        if (this.propertyToShowDetails.includes('Pendente rematrícula')) {
            filtroStatus.push(11);
        }
        if (this.propertyToShowDetails.includes('Rematriculado')) {
            filtroStatus.push(12);
        }
        if (this.propertyToShowDetails.includes('Não aceitou rematricular')) {
            filtroStatus.push(13);
        }
        if (this.propertyToShowDetails.includes('Idioma finalizado')) {
            filtroStatus.push(14);
        }
        const arr = [];
        this.diaSemanaQuantidadeAlunos = {
            'Domingo': 0,
            'Segunda-feira': 0,
            'Terça-feira': 0,
            'Quarta-feira': 0,
            'Quinta-feira': 0,
            'Sexta-feira': 0,
            'Sábado': 0,
            'Não disponível': 0,
        };
        // @ts-ignore
        _.forEach(this.dashboardCobranca, (item) => {
            // quantidade de alunos por dia da semana
            const diasSemana = _(item.cronograma_horario)
                .groupBy((i) => i.dia_semana)
                .value();
            const filtroDias = Object.keys(diasSemana);
            if (filtroDias.length === 0) {
                filtroDias.push('Não disponível');
            }
            filtroDias.forEach((i) => {
                this.diaSemanaQuantidadeAlunos[i]++;
            });
            if (filtroDias.some((i) => this.propertyToShowDetails.includes(i))) {
                const found = filtroStatus.some((r) => {
                    if (item.contratos) {
                        let valid = false;
                        _.forEach(item.contratos, (el) => {
                            if (el.status_id == r) {
                                valid = true;
                            }
                        });
                        return valid;
                    }
                    return r == null;
                });
                if (found) {
                    if (item.quantidade_parcelas_atraso <= 2 && ate2) {
                        arr.push(item);
                    }
                    else if (item.quantidade_parcelas_atraso >= 3 &&
                        item.quantidade_parcelas_atraso <= 4 &&
                        entre3e4) {
                        arr.push(item);
                    }
                    else if (item.quantidade_parcelas_atraso > 4 && mais4) {
                        arr.push(item);
                    }
                }
            }
        });
        return arr;
    }
    async showDetails() {
        this.loadingItems = true;
        this.propertyToShowDetails.forEach((field) => {
            switch (field) {
                case 'Alunos que possuem até 2 parcelas vencidas':
                    break;
                case 'Alunos que possuem entre 3 e 4 parcelas vencidas':
                    break;
                case 'Alunos que possuem acima de 4 parcelas vencidas':
                    break;
            }
        });
        this.loadingItems = false;
    }
    async showAluno(item) {
        this.selectedAluno = item;
        const form = this.$refs.showAluno;
        this.aluno = await alunoStore.getAluno(parseInt(this.selectedAluno.id, 0));
        form.show();
    }
    async carregaDados() {
        if (!this.panelOpen) {
            this.loading = true;
            await dashboardStore.getDashboardCobranca();
            this.loading = false;
            this.panelOpen = true;
        }
        else {
            this.panelOpen = false;
        }
    }
    async mounted() {
        await this.carregaDados();
    }
};
Cobranca = __decorate([
    Component({
        components: {
            ClickableCard,
            EntidadeLinkComponent,
            FinanceiroAlunoComponent,
            OcorrenciaComponent,
            ShowPopup,
            TextExpandableComponent,
            ContratoChipComponent,
        },
    })
], Cobranca);
export default Cobranca;
