import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import SaveButton from '@/components/SaveButton.vue';
import { ValidationObserver, ValidationProvider, Validator } from 'vee-validate';
import { newFinanceiroContaReceberComplete } from '@/interfaces/financeiro';
import { mainStore, financeiroStore, financeiroCategoriaStore } from '@/store';
import _ from 'lodash';
import { format } from 'date-fns';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value
});
let ContasReceberComponent = class ContasReceberComponent extends Vue {
    constructor() {
        super(...arguments);
        this.entityObject = null;
        this.ocorrencia = { id: 1, label: 'Único' };
        this.entityFinanceiroParcela = {
            valor_atualizado: 0,
            id: 0,
            colaborador_id: 0,
            aluno_id: 0,
            fornecedor_id: 0,
            vencimento: ''
        };
    }
    get financeiroCategoriasReceber() {
        return _.filter(financeiroCategoriaStore.FinanceiroCategorias, 
        // @ts-ignore
        (item) => item.tipo == 1 && item.classificacao != '1' && item.nome);
    }
    async submit() {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
            // @ts-ignore
            this.$refs.saveButton.changeLoadingStatus(false);
            return;
        }
        const fornecedor_id = null;
        const colaborador_id = null;
        const valor_integral = this.entityObject.valor_integral;
        const financeiroCreateComplete = {
            tipo: this.entityObject.tipo,
            data_emissao: this.entityObject.data_emissao,
            data_vencimento: this.entityFinanceiroParcela.vencimento,
            vencimento_inicial: this.entityFinanceiroParcela.vencimento,
            data_pagamento: this.entityObject.data_pagamento,
            numero_documento: this.entityObject.numero_documento,
            financeiro_categoria_id: this.entityObject.financeiro_categoria_id,
            conta_bancaria_id: this.entityObject.conta_bancaria_id,
            observacao: this.entityObject.observacao,
            valor_integral,
            total_pago: this.entityObject.total_pago,
            documento_fiscal_id: this.entityObject.documento_fiscal_id,
            valor_pago: this.entityObject.valor_pago,
            aluno_id: this.propAlunoId,
            fornecedor_id,
            colaborador_id,
            company_id: this.entityObject.company_id,
            unidade_id: this.entityObject.unidade_id,
            numero_vezes: this.entityObject.numero_parcelas,
            numero_parcelas: 1,
            periodicidade_id: this.ocorrencia.id,
            status_id: this.entityObject.status_id
        };
        await financeiroStore.createFinanceiroComplete(financeiroCreateComplete);
        // @ts-ignore
        await this.$refs.saveButton.changeLoadingStatus(false);
        this.$emit('closeDialog');
        this.$emit('onLoad');
    }
    async mounted() {
        this.entityObject = newFinanceiroContaReceberComplete();
        this.entityObject.company_id = mainStore.userProfile.company.id;
        this.entityObject.numero_documento = '';
        this.entityObject.data_emissao = format(new Date(), 'yyyy-MM-dd');
    }
};
__decorate([
    Prop({ type: Number, required: true })
], ContasReceberComponent.prototype, "propAlunoId", void 0);
ContasReceberComponent = __decorate([
    Component({
        components: {
            DateFieldComponent,
            SaveButton,
            ValidationObserver,
            ValidationProvider
        }
    })
], ContasReceberComponent);
export default ContasReceberComponent;
