import { __decorate } from "tslib";
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { alunoStore, companyConfiguracaoIntegracaoBancariaStore, dashboardStore, financeiroParcelaMovimentacaoStore, financeiroParcelaStore, plugnotasConfiguracaoStore, userActionStore, mainStore, } from '@/store';
import _ from 'lodash';
import CaixaAbrirComponent from '@/components/CaixaAbrirComponent.vue';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import FinanceiroAlterarVencimentoTituloComponent from '@/components/FinanceiroAlterarVencimentoTituloComponent.vue';
// import FinanceiroBaixaManualComponent from '@/components/FinanceiroBaixaManualComponent.vue';
import FinanceiroBaixarTituloComponent from '@/components/FinanceiroBaixarTituloComponent.vue';
import FinanceiroCancelarTituloComponent from '@/components/FinanceiroCancelarTituloComponent.vue';
import FinanceiroEstornoTituloComponent from '@/components/FinanceiroEstornoTituloComponent.vue';
import FinanceiroParcelaRenegociacaoComponent from '@/components/FinanceiroParcelaRenegociacaoComponent.vue';
import FinanceiroProtestarTituloComponent from '@/components/FinanceiroProtestarTituloComponent.vue';
import FinanceiroRenegociarTituloComponent from '@/components/FinanceiroRenegociarTituloComponent.vue';
import FinanceiroValorPagoTextComponent from '@/components/FinanceiroValorPagoTextComponent.vue';
import ImprimirTabelaComponent from '@/components/ImprimirTabelaComponent.vue';
import LogShowComponent from '@/components/LogShowComponent.vue';
import moment from 'moment';
import OcorrenciaEntidadeComponent from '@/components/OcorrenciaEntidadeComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import PixIntegradorPagamentoDialog from '@/components/PixIntegradorPagamentoDialog.vue';
import PlugnotasConsultaEmiteButton from '@/components/PlugnotasConsultaEmiteButton.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import ShowPopup from './ShowPopup.vue';
import ContasReceberComponent from '@/components/ContasReceberComponent.vue';
import UploadFilesComponent from '@/components/UploadFilesComponent.vue';
let FinanceiroAlunoComponent = class FinanceiroAlunoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.id = null;
        this.dialogAbrirCaixa = false;
        this.valorCursoParaReplicar = null;
        this.descontoCursoParaReplicar = null;
        this.descontoMaterialParaReplicar = null;
        this.dialogEstornoManual = false;
        this.dialogAnexos = false;
        this.dialogAdicionarAnexos = false;
        this.integracaoBancariaAtivada = false;
        this.loadingAnexos = false;
        this.AnexoUploadFiles = [];
        this.financeiroParcelaMovimentacaoItems = [];
        this.financeiroParcelaMovimentacaoHeaders = [
            { text: '', value: 'selected' },
            { text: 'Operação efetuada em', value: 'created_at' },
            { text: 'Data do pagamento', value: 'data_pagamento' },
            { text: 'Valor', value: 'valor_pago' },
            { text: 'Forma de pagamento', value: 'forma_pagamento' },
            { text: 'Observação', value: 'observacao' },
            { text: 'Caixa', value: 'caixa' },
            { text: '', value: 'actions' },
        ];
        this.anexosHeaders = [
            { text: 'Nome', value: 'blob.name' },
            { text: 'Tamanho', value: 'tamanho' },
            { text: 'Ações', value: 'actions' },
        ];
        this.filtroPorStatusSelected = [
            // @ts-ignore
            { text: 'Em aberto', value: this.getFinanceiroParcelaStatusIdEmAberto() },
            // @ts-ignore
            {
                text: 'Protestado',
                // @ts-ignore
                value: this.getFinanceiroParcelaStatusIdProtestado(),
            },
        ];
        this.filtroStatusOptions = [
            // @ts-ignore
            { text: 'Em aberto', value: this.getFinanceiroParcelaStatusIdEmAberto() },
            // @ts-ignore
            { text: 'Paga', value: this.getFinanceiroParcelaStatusIdPago() },
            // @ts-ignore
            { text: 'Cancelada', value: this.getFinanceiroParcelaStatusIdCancelada() },
            {
                text: 'Renegociada',
                // @ts-ignore
                value: this.getFinanceiroParcelaStatusIdRenegociada(),
            },
            {
                text: 'Protestado',
                // @ts-ignore
                value: this.getFinanceiroParcelaStatusIdProtestado(),
            },
        ];
        this.financeiroItems = [];
        this.financeiroExpandedItems = {};
        this.dialogAplicarVencimentoEmLote = false;
        this.dialogBaixarTitulos = false;
        this.dialogEstornoTitulo = false;
        this.dialogEstornoTituloObject = null;
        this.dialogCancelarTitulos = false;
        this.dialogRenegociarTitulos = false;
        this.dialogAlterarVencimentoTitulo = false;
        this.dialogProtestarTitulo = false;
        this.dialogRetirarProtestoTitulo = false;
        // public dialogBaixaManualTitulo = false;
        this.modoEdicaoEmLote = false;
        this.loadingFinanceiroDetails = true;
        this.backupFinanceiroItems = [];
        this.anexosSelected = [];
        this.modoEdicaoFinanceiroItems = [];
        this.search = '';
        this.tabelaRef = null;
        this.financeiroParcelaId = null;
        this.erroOrdem = false;
        this.financeiroItemsComDiferenca = {};
        this.dialogInfoRenegociacao = false;
        this.dialogInfoRenegociacaoFinanceiroParcelaId = null;
        this.infoRenegociacaoItem = {};
        this.changedItemVencimentoEmLote = { id: null, vencimento: '' };
        this.financeiroParcelaDetails = { item: {}, value: {} };
        this.plugnotasConfiguracao = null;
        this.currentFinanceiroItems = [];
    }
    get financeiroExpandedHeaders() {
        const h = [
            { text: 'Parcela', value: 'parcela_numero', width: '10px' },
            { text: 'Descrição', value: 'financeiro_observacao' },
            { text: 'Categoria', value: 'financeiro_categoria_nome' },
            { text: 'Valor integral (Sem desconto)', value: 'valor_integral' },
            { text: 'Percentual desconto', value: 'desconto_percentual' },
            { text: 'Valor com desconto', value: 'valor_original' },
            { text: 'Vencimento', value: 'vencimento' },
            { text: 'Valor atualizado', value: 'valor_atualizado' },
            { text: 'Valor pago', value: 'valor_pago' },
            { text: 'Ultimo recebimento', value: 'ultimo_pagamento_data' },
            { text: 'Status', value: 'financeiro_parcela_status_id' },
        ];
        if (!this.modoEdicaoEmLote) {
            h.unshift({ text: '', value: 'checked', width: '10px' });
        }
        return h;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get userActionsBaixarTituloAllow() {
        return userActionStore.baixarTituloAllow;
    }
    get permiteEmitirNota() {
        if (!this.plugnotasConfiguracao) {
            return false;
        }
        return (this.plugnotasConfiguracao.aceite_data_hora &&
            this.plugnotasConfiguracao.credito_quantidade > 0);
    }
    get userActionsCancelarTituloAllow() {
        return userActionStore.cancelarTituloAllow;
    }
    get userActionsRenegociarTituloAllow() {
        return userActionStore.renegociarTituloAllow;
    }
    get userActionsAlterarVencimentoDeTituloAllow() {
        return userActionStore.alterarVencimentoDeTituloAllow;
    }
    get userActionsAlterarVencimentoDeTituloRequestPermission() {
        return userActionStore.alterarVencimentoDeTituloRequestPermission;
    }
    get userActionsAlteracaoDeTitulosEmLoteValorDescontoVencimentoAllow() {
        return userActionStore.alteracaoDeTitulosEmLoteValorDescontoVencimentoAllow;
    }
    get userActionsAlteracaoDeTitulosEmLoteValorPagoStatusAllow() {
        return userActionStore.alteracaoDeTitulosEmLoteValorPagoStatusAllow;
    }
    get userActionsProtestarTituloAllow() {
        return userActionStore.protestarTituloAllow;
    }
    get userActionsLancamentoDeBaixaManualAllow() {
        return userActionStore.lancamentoDeBaixaManualAllow;
    }
    get userActionsAdicionarAnexo() {
        return userActionStore.adicionarAnexos;
    }
    get userActionExcluirAnexos() {
        return userActionStore.excluirAnexos;
    }
    get selectedFinanceiroMovimentacaoItems() {
        return _.filter(this.financeiroParcelaMovimentacaoItems, (item) => item.selected);
    }
    get financeiroItemsChecked() {
        return _.filter(this.financeiroItems, (item) => item.checked);
    }
    get userActionsAcessoAOLogDoSistema() {
        return userActionStore.acessoAOLogDoSistema;
    }
    get filteredFinanceiroItems() {
        if (this.modoEdicaoEmLote) {
            return this.modoEdicaoFinanceiroItems;
        }
        else {
            if (this.filtroPorStatusSelected.length === 0) {
                return [];
            }
            const ids = _.map(this.filtroPorStatusSelected, (item) => item.value);
            return _.filter(this.financeiroItems, (item) => ids.includes(item.financeiro_parcela_status_id));
        }
    }
    get vencimentosMesmaData() {
        const obj = {};
        _.forEach(this.financeiroItems, (item) => {
            if (!Object.keys(obj).includes(item.vencimento)) {
                obj[item.vencimento] = [item];
            }
            else {
                obj[item.vencimento].push(item);
            }
        });
        let repete = false;
        for (const key of Object.keys(obj)) {
            if (obj[key].length > 1) {
                const repeteVencimentoMateriais = _.filter(obj[key], [
                    'financeiro_categoria_id',
                    // @ts-ignore
                    this.getFinanceiroCategoriaMaterialDidatico104(),
                ]).length > 1;
                const repeteVencimentoCursos = _.filter(obj[key], [
                    'financeiro_categoria_id',
                    // @ts-ignore
                    this.getFinanceiroCategoriaCurso105(),
                ]).length > 1;
                if (repeteVencimentoMateriais || repeteVencimentoCursos) {
                    repete = true;
                    break;
                }
            }
        }
        return repete;
    }
    get mesAtual() {
        const data = new Date();
        return [
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro',
        ][data.getMonth()];
    }
    get getFinanceiroParcelaSelecionados() {
        return _.filter(this.financeiroItems, (item) => item.checked);
    }
    getCurrentFinanceiroItems(item) {
        this.currentFinanceiroItems = item;
    }
    async onchangeAbrirCaixa(val, oldVal) {
        if (!val) {
            await dashboardStore.getDashboardCaixa();
        }
    }
    openInfoRenegociacaoDialog(item) {
        this.dialogInfoRenegociacaoFinanceiroParcelaId = item.id;
        this.dialogInfoRenegociacao = true;
    }
    closeInfoRenegociacaoDialog() {
        this.dialogInfoRenegociacao = false;
        this.infoRenegociacaoItem = {};
    }
    showLog(ref) {
        const form = ref;
        form.show();
    }
    showHistoricoOcorrencias(ref) {
        const form = ref;
        form.show();
    }
    async onChange(item) {
        this.financeiroItemsComDiferenca[item.id] = true;
    }
    getCellClass(id, field, newValue) {
        const originalValue = _.find(this.backupFinanceiroItems, (i) => i.id == id)[field];
        if (originalValue != newValue) {
            return 'yellow';
        }
        else {
            return '';
        }
    }
    async showPix(item) {
        // @ts-ignore
        const form = this.$refs.PixIntegradorPagamentoDialog;
        if (this.userProfile.company.escola_id == 1) {
            item.syngoo_mensagem_id = 9;
        }
        else if (this.userProfile.company.escola_id == 2) {
            item.syngoo_mensagem_id = 16;
        }
        else if (this.userProfile.company.escola_id == 3) {
            item.syngoo_mensagem_id = 17;
        }
        form.show(item);
    }
    async replicarValorCurso() {
        const valor_substituir = this.valorCursoParaReplicar
            ? this.valorCursoParaReplicar
            : 0;
        for (const obj of this.financeiroItems) {
            if (
            // @ts-ignore
            obj.financeiro_categoria_id === this.getFinanceiroCategoriaCurso105()) {
                obj.valor_integral = valor_substituir;
            }
        }
    }
    async replicarDescontoCurso() {
        const valor_substituir = this.descontoCursoParaReplicar
            ? this.descontoCursoParaReplicar
            : 0;
        for (const obj of this.financeiroItems) {
            if (
            // @ts-ignore
            obj.financeiro_categoria_id === this.getFinanceiroCategoriaCurso105()) {
                obj.desconto_percentual = valor_substituir;
            }
        }
    }
    async replicarDescontoMaterial() {
        const valor_substituir = this.descontoMaterialParaReplicar
            ? this.descontoMaterialParaReplicar
            : 0;
        for (const obj of this.financeiroItems) {
            if (
            // @ts-ignore
            obj.financeiro_categoria_id === this.getFinanceiroCategoriaMaterialDidatico104()) {
                obj.desconto_percentual = valor_substituir;
            }
        }
    }
    async imprimirTitulos() {
        const html = await financeiroParcelaStore.imprimirTitulos({
            payload: this.financeiroItemsChecked,
            aluno_id: this.id,
        });
        if (html) {
            const w = window.open();
            w.document.write(html);
        }
    }
    async salvarAtualizacaoVencimentoEmLote() {
        let isInvalid = false;
        const items = this.financeiroItems;
        for (const item of items) {
            if (this.$refs[`dateField` + item.id]) {
                if (
                // @ts-ignore
                !(await this.$refs[`dateField` + item.id].$refs.observer.validate())) {
                    isInvalid = true;
                }
            }
        }
        if (isInvalid) {
            return;
        }
        await financeiroParcelaStore.updateFinanceiroVencimentoEmLote(this.financeiroItems);
        const filtros = [`financeiro.aluno_id = ${this.id}`];
        if (this.propModoLeituraCobranca) {
            filtros.push(
            // @ts-ignore
            `financeiro_parcela.status_id = ${this.getFinanceiroParcelaStatusIdEmAberto()}`);
        }
        this.financeiroItems = await financeiroParcelaStore.getFinanceiroParcelas(filtros);
        this.modoEdicaoEmLote = false;
        this.$emit('ativaSalvar');
    }
    async ativarModoEdicaoEmLote() {
        this.backupFinanceiroItems = JSON.parse(JSON.stringify(this.financeiroItems));
        this.modoEdicaoFinanceiroItems = this.filteredFinanceiroItems;
        this.modoEdicaoEmLote = true;
        this.$emit('desativaSalvar');
    }
    async cancelarAlterarVencimentoDeTituloEmLote() {
        this.loadingFinanceiroDetails = true;
        this.financeiroItems = this.backupFinanceiroItems;
        this.modoEdicaoEmLote = false;
        this.loadingFinanceiroDetails = false;
        this.financeiroItemsComDiferenca = {};
        this.$emit('ativaSalvar');
    }
    get infoAlterarDataEmLote() {
        const filteredItemIndex = _.findIndex(this.filteredFinanceiroItems, (val) => val.id === this.changedItemVencimentoEmLote.id);
        const itemDoBackup = _.find(this.backupFinanceiroItems, (item) => item.id === this.changedItemVencimentoEmLote.id);
        const daysDiff = moment(this.changedItemVencimentoEmLote.vencimento).date();
        let monDiff = moment(this.changedItemVencimentoEmLote.vencimento).diff(moment(itemDoBackup.vencimento), 'M');
        const yearDiff = moment(this.changedItemVencimentoEmLote.vencimento).diff(moment(itemDoBackup.vencimento), 'y');
        monDiff = monDiff - yearDiff * 12;
        let quantidadeParcelasAlteradas = 0;
        for (let i = filteredItemIndex; i < this.filteredFinanceiroItems.length; i++) {
            quantidadeParcelasAlteradas++;
        }
        return {
            daysDiff,
            monDiff,
            yearDiff,
            quantidadeParcelasAlteradas,
        };
    }
    confirmAlterarVencimentoEmLote(filteredItem) {
        this.dialogAplicarVencimentoEmLote = true;
        this.changedItemVencimentoEmLote = filteredItem;
    }
    cancelarAlterarDataEmLote() {
        this.dialogAplicarVencimentoEmLote = false;
    }
    alterarDataEmLote() {
        const filteredItem = this.changedItemVencimentoEmLote;
        const filteredItemIndex = _.findIndex(this.currentFinanceiroItems, (val) => val.id === filteredItem.id);
        const filteredItemDate = new Date(filteredItem.vencimento);
        const startedLeapYear = new Date(filteredItemDate.getFullYear(), 1, 29).getDate() === 29;
        const itemDoBackup = _.find(this.backupFinanceiroItems, (item) => item.id === filteredItem.id);
        let monDiff = moment(this.changedItemVencimentoEmLote.vencimento).diff(moment(itemDoBackup.vencimento), 'M');
        const yearDiff = moment(this.changedItemVencimentoEmLote.vencimento).diff(moment(itemDoBackup.vencimento), 'y');
        monDiff = monDiff - yearDiff * 12;
        for (let i = filteredItemIndex + 1; i < this.currentFinanceiroItems.length; i++) {
            const item = this.currentFinanceiroItems[i];
            const backupVencimento = _.find(this.backupFinanceiroItems, (val) => val.id === item.id).vencimento;
            let tempData = moment(backupVencimento);
            if (monDiff !== 0) {
                tempData.add(monDiff, 'M');
            }
            if (yearDiff !== 0) {
                tempData.add(yearDiff, 'y');
            }
            const beforeDayChange = moment(tempData);
            tempData.date(moment(this.changedItemVencimentoEmLote.vencimento).date());
            if (beforeDayChange.month() !== tempData.month()) {
                tempData = beforeDayChange.endOf('month').hour(0);
            }
            const newVencimento = tempData.toDate();
            // @ts-ignore
            item.vencimento = this.datetimeToDate(newVencimento.toISOString());
            const financeiroItemIndex = _.findIndex(this.financeiroItems, (val) => val.id === item.id);
            this.financeiroItems[financeiroItemIndex] = item;
        }
        this.financeiroItemsComDiferenca = {};
        this.dialogAplicarVencimentoEmLote = false;
    }
    async abrirCaixa() {
        this.dialogAbrirCaixa = !this.dialogAbrirCaixa;
        await dashboardStore.getDashboardCaixa();
    }
    async cancelar() {
        this.dialogCancelarTitulos = !this.dialogCancelarTitulos;
    }
    async getFinanceiroParcelaDetails({ item, value }) {
        this.financeiroParcelaDetails = { item, value };
        if (value) {
            this.financeiroParcelaMovimentacaoItems = [];
            this.financeiroParcelaId = item.id;
            await financeiroParcelaMovimentacaoStore.getFinanceiroParcelaMovimentacoesByParcelaId(item.id);
            this.financeiroParcelaMovimentacaoItems =
                financeiroParcelaMovimentacaoStore.financeiroParcelaMovimentacoes;
            this.financeiroParcelaMovimentacaoItems = [
                ...this.financeiroParcelaMovimentacaoItems,
            ];
        }
    }
    async estornarBaixa(item) {
        this.dialogEstornoTituloObject = item;
        this.dialogEstornoTitulo = true;
    }
    async imprimirRecibo(item) {
        let reciboHTML = null;
        let financeiro_parcela_movimentacao_ids = _.filter(this.financeiroParcelaMovimentacaoItems, (i) => i.selected);
        financeiro_parcela_movimentacao_ids = _.map(financeiro_parcela_movimentacao_ids, 'id');
        reciboHTML = await financeiroParcelaStore.imprimirRecibo({
            financeiro_parcela_movimentacao_ids,
        });
        if (reciboHTML) {
            const w = window.open();
            w.document.write(reciboHTML);
        }
    }
    getAnexoSize(size) {
        if (size / 1000 < 1000) {
            const value = (size / 1000).toFixed(2);
            return `${value} kB`;
        }
        else if (size / 1000 >= 1000) {
            const value = (size / 1000000).toFixed(2);
            return `${value} MB`;
        }
    }
    async baixarAnexo(item, blob) {
        const clickedBlob = await financeiroParcelaMovimentacaoStore.getFinanceiroParcelaMovimentacaoAnexo({
            id: item.id,
            full_name: blob.blob.full_name,
        });
        window.open(clickedBlob.download_url, '_blank').focus();
    }
    async onClickAdicionarAnexosDialog() {
        this.dialogAnexos = false;
        this.dialogAdicionarAnexos = true;
    }
    async onClickAdicionarAnexosSubmit() {
        this.dialogAdicionarAnexos = false;
        if (financeiroParcelaMovimentacaoStore.financeiroParcelaMovimentacoes
            ?.length > 0) {
            const caixa_movimentacao_detalhamento = financeiroParcelaMovimentacaoStore.financeiroParcelaMovimentacoes.at(-1
            // @ts-ignore
            ).caixa_movimentacao_detalhamento;
            if (caixa_movimentacao_detalhamento?.length > 0) {
                const caixa_movimentacao_id = caixa_movimentacao_detalhamento.at(-1).caixa_movimentacao_id;
                await financeiroParcelaMovimentacaoStore.createFinanceiroParcelaMovimentacaoAnexos({
                    files: this.AnexoUploadFiles,
                    caixa_movimentacao_id,
                });
            }
        }
    }
    async onClickDeletarAnexos(item) {
        this.$swal({
            title: 'Deseja realmente excluir o anexo?',
            text: 'Ao deletar um anexo o mesmo não poderá ser restaurado!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await financeiroParcelaMovimentacaoStore.deleteFinanceiroParcelaMovimentacaoAnexos(item.blob.full_name);
                this.onClickMostrarAnexos();
            }
        });
    }
    async onClickMostrarAnexos() {
        const anexos = [];
        this.loadingAnexos = true;
        this.dialogAnexos = true;
        for (const parcela of financeiroParcelaMovimentacaoStore.financeiroParcelaMovimentacoes) {
            const data = await financeiroParcelaMovimentacaoStore.getFinanceiroParcelaMovimentacaoAnexos({
                id: parcela.id,
            });
            if (data.length > 0) {
                _.forEach(data, (item) => {
                    item.id = parcela.id;
                    anexos.push(item);
                });
            }
        }
        this.anexosSelected = anexos;
        this.loadingAnexos = false;
    }
    async estornoOnCancel() {
        this.dialogEstornoTitulo = false;
        this.dialogEstornoTituloObject = null;
        this.financeiroParcelaDetails = { item: {}, value: {} };
    }
    async estornoOnConfirm() {
        this.dialogEstornoTitulo = false;
        this.loadingFinanceiroDetails = true;
        await this.getFinanceiroParcelaDetails(this.financeiroParcelaDetails);
        const filtros = [
            `financeiro.aluno_id = ${this.dialogEstornoTituloObject.aluno_id}`,
        ];
        if (this.propModoLeituraCobranca) {
            filtros.push(
            // @ts-ignore
            `financeiro_parcela.status_id = ${this.getFinanceiroParcelaStatusIdEmAberto()}`);
        }
        this.financeiroItems = await financeiroParcelaStore.getFinanceiroParcelas(filtros);
        this.dialogEstornoTituloObject = null;
        this.loadingFinanceiroDetails = false;
    }
    async onCancel() {
        this.loadingFinanceiroDetails = true;
        this.dialogBaixarTitulos = false;
        this.dialogCancelarTitulos = false;
        this.dialogRenegociarTitulos = false;
        this.dialogAlterarVencimentoTitulo = false;
        this.dialogProtestarTitulo = false;
        this.dialogRetirarProtestoTitulo = false;
        // this.dialogBaixaManualTitulo = false;
        this.$nextTick(async () => {
            const filtros = [`financeiro.aluno_id = ${this.id}`];
            if (this.propModoLeituraCobranca) {
                filtros.push(
                // @ts-ignore
                `financeiro_parcela.status_id = ${this.getFinanceiroParcelaStatusIdEmAberto()}`);
            }
            this.financeiroItems = await financeiroParcelaStore.getFinanceiroParcelas(filtros);
        });
        this.loadingFinanceiroDetails = false;
    }
    async ajustarOrdem() {
        await alunoStore.alunoArrumaOrdemFinanceiro(this.id);
        this.buscar();
        this.erroOrdem = false;
    }
    handleLancamentoTitulo() {
        const form = this.$refs.showContaReceber;
        form.show();
    }
    closeDialog() {
        const popup = this.$refs.showContaReceber;
        if (popup) {
            popup.close();
            popup.dialog = false;
        }
    }
    async buscar() {
        this.loadingFinanceiroDetails = true;
        this.financeiroItems = [];
        this.id = parseInt(this.propData, 0);
        const filtros = [`financeiro.aluno_id = ${this.id}`, `financeiro.tipo = 0`];
        if (this.propModoLeituraCobranca) {
            filtros.push(
            // @ts-ignore
            `financeiro_parcela.status_id = ${this.getFinanceiroParcelaStatusIdEmAberto()}`);
        }
        this.financeiroItems = await financeiroParcelaStore.getFinanceiroParcelas(filtros);
        this.erroOrdem = await alunoStore.getAlunoChecarOrdemFinanceiro(this.id);
        this.loadingFinanceiroDetails = false;
    }
    async mounted() {
        await dashboardStore.getDashboardCaixa();
        this.integracaoBancariaAtivada =
            await companyConfiguracaoIntegracaoBancariaStore.getCompanyConfiguracaoIntegracaoBancariaAtiva();
        this.buscar();
        setTimeout(() => {
            this.tabelaRef = this.$refs.tabela;
        }, 2000);
        this.plugnotasConfiguracao =
            await plugnotasConfiguracaoStore.getPlugnotasConfiguracaoMe();
    }
};
__decorate([
    Prop({ type: Number, default: '' })
], FinanceiroAlunoComponent.prototype, "propData", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], FinanceiroAlunoComponent.prototype, "propModoLeituraCobranca", void 0);
__decorate([
    Watch('dialogAbrirCaixa')
], FinanceiroAlunoComponent.prototype, "onchangeAbrirCaixa", null);
FinanceiroAlunoComponent = __decorate([
    Component({
        components: {
            CaixaAbrirComponent,
            DateFieldComponent,
            FinanceiroAlterarVencimentoTituloComponent,
            // FinanceiroBaixaManualComponent,
            FinanceiroBaixarTituloComponent,
            FinanceiroCancelarTituloComponent,
            FinanceiroEstornoTituloComponent,
            FinanceiroParcelaRenegociacaoComponent,
            FinanceiroProtestarTituloComponent,
            FinanceiroRenegociarTituloComponent,
            FinanceiroValorPagoTextComponent,
            ImprimirTabelaComponent,
            LogShowComponent,
            OcorrenciaEntidadeComponent,
            OpenNewTabComponent,
            PixIntegradorPagamentoDialog,
            PlugnotasConsultaEmiteButton,
            TextExpandableComponent,
            ShowPopup,
            ContasReceberComponent,
            UploadFilesComponent,
        },
    })
], FinanceiroAlunoComponent);
export default FinanceiroAlunoComponent;
