import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import _ from 'lodash';
import { financeiroParcelaStore, companyConfiguracaoNegociacaoStore, } from '@/utils/store-accessor';
import ClickableCard from '@/components/ClickableCard.vue';
import DefinirDescontoComponent from '@/components/DefinirDescontoComponent.vue';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
let FinanceiroRenegociarTituloComponent = class FinanceiroRenegociarTituloComponent extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Tipo', value: 'financeiro_categoria_nome' },
            { text: 'Vencimento', value: 'vencimento' },
            { text: 'Valor integral', value: 'valor_integral' },
            { text: 'Valor original', value: 'valor_original' },
            { text: 'Valor atualizado', value: 'valor_atualizado' },
            { text: 'Valor pago', value: 'valor_pago' },
        ];
        this.jurosSelected = ['Juros'];
        this.multaSelected = ['Multa'];
        this.quantidadeVezesSelected = [];
        this.negociacaoSelected = {
            valorDesconto: 0,
            percentualDesconto: 0,
            valorAcrescimo: 0
        };
        this.perdaDescontoSelected = ['Perda de desconto'];
        this.novaQuantidadeParcelas = 1;
        this.novoValorTotal = 0;
        this.novoValorParcela = 0;
        this.observacao = '';
        this.dataPrimeiraParcela = new Date().toISOString().split('T')[0];
        this.loading = false;
    }
    get disableConfirmButton() {
        return (this.dataPrimeiraParcela.length <= 0 ||
            !this.dataPrimeiraParcela ||
            this.loading ||
            this.quantidadeVezesSelected.length <= 0 ||
            (this.getValorTotalOriginal + this.getValorRenegociacao - this.negociacaoSelected.valorDesconto) <= 0);
    }
    onCancel() {
        this.$emit('on-cancel');
    }
    get valorJuros() {
        return _.sumBy(this.propTitulosItems, (item) => {
            // @ts-ignore
            return item.valor_juros;
        });
    }
    get valorMulta() {
        return _.sumBy(this.propTitulosItems, (item) => {
            // @ts-ignore
            return item.valor_multa;
        });
    }
    get valorPerdaDesconto() {
        return _.sumBy(this.propTitulosItems, (item) => {
            // @ts-ignore
            return item.desconto_valor_perdido;
        });
    }
    async calculaNovosValores(tipo) {
        switch (tipo) {
            case 1: // por novo valor total
                this.novoValorParcela =
                    this.novoValorTotal / this.novaQuantidadeParcelas;
                break;
            case 2: // por nova quantidade de parcelas
                this.novoValorParcela =
                    this.novoValorTotal / this.novaQuantidadeParcelas;
                break;
            case 3: // por novo valor de parcela
                this.novoValorTotal =
                    this.novoValorParcela * this.novaQuantidadeParcelas;
                break;
        }
    }
    get getValorEmAberto() {
        let valorTotal = 0;
        this.propTitulosItems.forEach((item) => {
            valorTotal += item.valor_atualizado;
        });
        this.novoValorTotal = parseFloat(valorTotal.toFixed(2));
        this.novoValorParcela = parseFloat(valorTotal.toFixed(2));
        return +valorTotal.toFixed(2);
    }
    get getValorTotalOriginal() {
        let valorTotal = 0;
        this.propTitulosItems.forEach((item) => {
            valorTotal += item.valor_original - item.valor_pago;
        });
        return +valorTotal.toFixed(2);
    }
    get companyConfiguracaoNegociacoes() {
        return companyConfiguracaoNegociacaoStore.companyConfiguracaoNegociacoes;
    }
    get getValorRenegociacao() {
        this.observacao = '';
        let valorTotal = 0;
        this.propTitulosItems.forEach((item) => {
            if (this.jurosSelected.length > 0) {
                valorTotal += item.valor_juros;
            }
            if (this.multaSelected.length > 0) {
                valorTotal += item.valor_multa;
            }
            if (this.perdaDescontoSelected.length > 0) {
                valorTotal += item.desconto_valor_perdido;
            }
        });
        return +valorTotal.toFixed(2);
    }
    async renegociarTitulos() {
        this.loading = true;
        const titulos = _(this.propTitulosItems)
            .groupBy('financeiro_categoria_id')
            .map((item, id) => ({
            id,
            valor: _.sumBy(item, 'valor_atualizado'),
        }))
            .value();
        await financeiroParcelaStore.renegociarFinanceiroParcelas({
            titulos: this.propTitulosItems,
            titulos_por_categoria: titulos,
            valor_devido: this.getValorEmAberto,
            observacao: this.observacao,
            valor_renegociado: this.getValorTotalOriginal +
                this.getValorRenegociacao -
                this.negociacaoSelected.valorDesconto +
                this.negociacaoSelected.valorAcrescimo,
            quantidade_parcelas: this.quantidadeVezesSelected[1].numero_vezes,
            data_primeira_parcela: this.dataPrimeiraParcela,
        });
        this.onCancel();
        this.loading = false;
    }
    async mounted() {
        await companyConfiguracaoNegociacaoStore.getCompanyConfiguracaoNegociacoes();
    }
};
__decorate([
    Prop({ type: Array, default: () => [] })
], FinanceiroRenegociarTituloComponent.prototype, "propTitulosItems", void 0);
FinanceiroRenegociarTituloComponent = __decorate([
    Component({
        components: {
            DateFieldComponent,
            ClickableCard,
            DefinirDescontoComponent,
        },
    })
], FinanceiroRenegociarTituloComponent);
export default FinanceiroRenegociarTituloComponent;
