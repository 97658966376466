import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import { financeiroParcelaStore, userActionStore } from '@/utils/store-accessor';
import RequestPermissionComponent from '@/components/RequestPermissionComponent.vue';
let FinanceiroCancelarTituloComponent = class FinanceiroCancelarTituloComponent extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Tipo', value: 'financeiro_categoria_nome' },
            { text: 'Vencimento', value: 'vencimento' },
            { text: 'Valor integral', value: 'valor_integral' },
            { text: 'Valor original', value: 'valor_original' },
            { text: 'Valor atualizado', value: 'valor_atualizado' },
            { text: 'Valor pago', value: 'valor_pago' },
        ];
        this.novoDiaVencimento = null;
        this.observacao = '';
        this.requestPermissionDialog = false;
    }
    onCancel() {
        this.$emit('on-cancel');
    }
    get userActionsAlterarVencimentoDeTituloObject() {
        return userActionStore.alterarVencimentoDeTituloObject;
    }
    get userActionsAlterarVencimentoDeTituloRequestPermission() {
        return userActionStore.alterarVencimentoDeTituloRequestPermission;
    }
    get diaDoTitulo() {
        const vencimento = this.propTitulosItems[0].vencimento;
        this.novoDiaVencimento = vencimento.split('-')[2];
        return vencimento.split('-')[2];
    }
    get maiorDiaDoMes() {
        const date = new Date();
        const ultimaDataMes = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        const ultimoDia = parseFloat(ultimaDataMes.toISOString().split('T')[0].split('-').at(-1));
        return ultimoDia;
    }
    async alterarVencimento(allowed = true) {
        if (this.userActionsAlterarVencimentoDeTituloRequestPermission &&
            !this.requestPermissionDialog) {
            this.requestPermissionDialog = true;
            return;
        }
        if (!allowed) {
            return;
        }
        const id = this.propTitulosItems[0].id;
        let vencimento = this.propTitulosItems[0].vencimento;
        const aluno_id = this.propTitulosItems[0].aluno_id;
        vencimento = vencimento.split('-');
        vencimento =
            vencimento[0] + '-' + vencimento[1] + '-' + this.novoDiaVencimento;
        // @ts-ignore
        await financeiroParcelaStore.updateFinanceiroParcela({
            // @ts-ignore
            id,
            vencimento,
            aluno_id,
        });
        this.onCancel();
    }
};
__decorate([
    Prop({ type: Array, default: () => [] })
], FinanceiroCancelarTituloComponent.prototype, "propTitulosItems", void 0);
FinanceiroCancelarTituloComponent = __decorate([
    Component({
        components: {
            RequestPermissionComponent,
        },
    })
], FinanceiroCancelarTituloComponent);
export default FinanceiroCancelarTituloComponent;
