var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Ocorrências")]),_c('v-spacer'),_c('v-toolbar-items')],1),_c('v-card',{staticClass:"ma-3 pa-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"})]),_c('v-card-text',[_c('v-list',{attrs:{"two-line":"","subheader":""}},[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"loading-text":"Carregando...","footer-props":{itemsPerPageOptions:[-1]},"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.created_at))+" ")]}},{key:"item.objecao_nome",fn:function(ref){
var item = ref.item;
return [(item.syngoo_mensagem_id)?_c('v-chip',{attrs:{"color":"green"}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v("mdi-whatsapp")])],1),_vm._v(" "+_vm._s(item.objecao_nome)+" ")],1):_c('span',[_vm._v(" "+_vm._s(item.objecao_nome)+" ")])]}},{key:"item.entidade_id",fn:function(ref){
var item = ref.item;
return [_c('open-new-tab-component',{attrs:{"propObjectLabel":((item.entidade_nome) + " - " + (item.entidade_tipo)),"propObjectId":item.entidade_id,"propToolName":item.entidade_tipo}})]}},{key:"item.observacao",fn:function(ref){
var item = ref.item;
return [_c('text-expandable-component',[_vm._v(" "+_vm._s(item.observacao)+" ")])]}}],null,true)})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }