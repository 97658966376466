import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import _ from 'lodash';
import { financeiroParcelaStore } from '@/utils/store-accessor';
let FinanceiroProtestarTituloComponent = class FinanceiroProtestarTituloComponent extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Tipo', value: 'financeiro_categoria_nome' },
            { text: 'Vencimento', value: 'vencimento' },
            { text: 'Valor integral', value: 'valor_integral' },
            { text: 'Valor original', value: 'valor_original' },
            { text: 'Valor atualizado', value: 'valor_atualizado' },
            { text: 'Valor pago', value: 'valor_pago' },
        ];
        this.observacao = '';
    }
    onCancel() {
        this.$emit('on-cancel');
    }
    async protestar() {
        const ids = _.map(this.propTitulosItems, 'id');
        if (this.propRemoveMode) {
            await financeiroParcelaStore.retirarProtestoFinanceiroParcelas({
                ids,
                observacao: this.observacao,
            });
        }
        else {
            await financeiroParcelaStore.protestarFinanceiroParcelas({
                ids,
                observacao: this.observacao,
            });
        }
        this.onCancel();
    }
};
__decorate([
    Prop({ type: Array, default: () => [] })
], FinanceiroProtestarTituloComponent.prototype, "propTitulosItems", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], FinanceiroProtestarTituloComponent.prototype, "propRemoveMode", void 0);
FinanceiroProtestarTituloComponent = __decorate([
    Component
], FinanceiroProtestarTituloComponent);
export default FinanceiroProtestarTituloComponent;
